import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom"; // useLocation to detect route changes
import Introduction from "../components/Introduction/Introduction";
import ServiceSection from "../components/ServiceSection/ServiceSection";
import ShotokanIntro from "../components/ShotokanIntro/Intro";
import Testimonials from "../components/Testimonial/Testimonials";
import heroImg from "../assets/images/shotokan.jpeg";
import heroImg03 from "../assets/images/pradeep_sir.jpeg";
import "../styles/home.css";

const Home = () => {
  const location = useLocation(); // Hook to get current location

  // Scroll to top when location changes (back navigation)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [location]); // Run this effect every time the route/location changes

  return (
    <>
      {/* Introduction section */}
      <section className='section-gap' aria-label="Introduction section">
        <Container>
          <Row>
            <Col lg="12">
              <Introduction heroImg={heroImg} heroImg03={heroImg03} />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Service section */}
      <section className='section-gap' aria-label="Service section">
        <Container>
          <Row>
            <Col lg="12">
              <ServiceSection />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Shotokan Intro section */}
      <section aria-label="Shotokan Intro section" className='section-gap'>
        <Container>
          <Row>
            <Col lg="12">
              <ShotokanIntro />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Testimonials section */}
      <section aria-label="Testimonials section" className='section-gap'>
        <Container>
          <Row>
            <Col lg="12">
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
