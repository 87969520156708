import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RemoveStudent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Dependency array added

  const [students, setStudents] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState('');

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get('https://karatesportsclubhubli.com/fetch_students_Permanant_delete.php');
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
  }, []); // Empty array ensures this runs only once

  const handleRemove = async () => {
    if (!selectedStudentId) {
      alert('Please select a student.');
      return;
    }

    try {
      const response = await axios.post('https://karatesportsclubhubli.com/delete_student_permanantly.php', {
        id: selectedStudentId // Correctly pass the student ID
      });

      // Check the message in the response
      if (response.data.message) {
        alert(response.data.message);
        // Update the students list by filtering out the removed student
        setStudents(students.filter(student => student.student_id !== selectedStudentId));
        setSelectedStudentId(''); // Reset selection after successful deletion
      } else {
        alert('Error removing student: ' + response.data.error);
      }
    } catch (error) {
      console.error('Error removing student:', error);
      alert('Error removing student: ' + error.message);
    }
  };

  return (
    <div>
      <h2>Delete Student Permanently</h2>
      <select onChange={(e) => setSelectedStudentId(e.target.value)} value={selectedStudentId}>
        <option value="">Select a student</option>
        {students.map(student => (
          <option key={student.student_id} value={student.student_id}>
            {student.name}
          </option>
        ))}
      </select>
      <button onClick={handleRemove}>Delete Student Permanently</button>
    </div>
  );
};

export default RemoveStudent;
