import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './../styles/service5.css';

const MediaGallery = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [mediaFiles, setMediaFiles] = useState([]);
    const [error, setError] = useState(null);
    const [fullscreenMedia, setFullscreenMedia] = useState(null);

    useEffect(() => {
        // Fetch media file names from the backend using Axios
        axios.get('https://karatesportsclubhubli.com/fetch_media_files.php')
            .then(response => {
                if (Array.isArray(response.data)) {
                    const fileUrls = response.data.map(fileName => 
                        `https://karatesportsclubhubli.com/media_file.php?file=${encodeURIComponent(fileName)}`
                    );
                    setMediaFiles(fileUrls);
                } else {
                    throw new Error('Response data is not an array.');
                }
            })
            .catch(err => {
                console.error('Error fetching media files:', err);
                setError('Failed to load media files.');
            });
    }, []);

    const handleItemClick = (url) => {
        setFullscreenMedia(url);
    };

    const closeFullscreen = () => {
        setFullscreenMedia(null);
    };

    return (
        <div>
            <h1>Media Gallery</h1>
            {error && <p>{error}</p>}
            <div className="gallery-container">
                <div className="gallery">
                    {mediaFiles.map((url, index) => (
                        <div key={index} className="gallery-item" onClick={() => handleItemClick(url)}>
                            {url.match(/\.(jpeg|jpg|gif|png)$/) ? (
                                <img src={url} alt={`Media ${index}`} />
                            ) : url.match(/\.(mp4|webm)$/) ? (
                                <video src={url} controls />
                            ) : null}
                            <p>Media {index + 1}</p>
                        </div>
                    ))}
                </div>
            </div>
            {fullscreenMedia && (
                <div className="fullscreen-media" onClick={closeFullscreen}>
                    {fullscreenMedia.match(/\.(jpeg|jpg|gif|png)$/) ? (
                        <img src={fullscreenMedia} alt="Fullscreen Media" />
                    ) : fullscreenMedia.match(/\.(mp4|webm)$/) ? (
                        <video src={fullscreenMedia} controls autoPlay />
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default MediaGallery;
