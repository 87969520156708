import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import "../styles/service1.css";

const Programs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <section className="programs">
      <Container>
        <Row>
          <Col lg="12" className="text-center">
            <h1>Our Programs</h1>
            <h3>Explore the various training programs we offer!</h3>
          </Col>
          <Col lg="4" className="program-item">
            <h3>Kids Program</h3>
            <h5>
              Focus on discipline, basic techniques, fun, and Basic Katas.
              Suitable for ages 5-12.
            </h5>
          </Col>
          <Col lg="4" className="program-item">
            <h3>Adult Program</h3>
            <h5>
              Emphasis on fitness, self-defense, and advanced techniques. Open
              to all skill levels.
            </h5>
          </Col>
          <Col lg="4" className="program-item">
            <h3>Advanced Training</h3>
            <h5>
              For higher belts, focusing on sparring, kata, and competition
              preparation.
            </h5>
          </Col>
        </Row>
        <Row>
          <Col lg="4" className="program-item">
            <h3>Personality Development</h3>
            <h5>
              Fosters self-confidence, discipline, and leadership skills through
              structured training and personal growth challenges.
            </h5>
          </Col>
          <Col lg="4" className="program-item">
            <h3>Women's Self-Defense</h3>
            <h5>
              Empowerment and safety techniques specifically for women. Build
              confidence and skills.
            </h5>
          </Col>
          <Col lg="4" className="program-item">
            <h3>Special Workshops</h3>
            <h5>
              Join our workshops on topics like kata, weapon training, and more.
              Check the schedule for details.
            </h5>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="text-center">
            <h3>Instructor Profiles</h3>
            <h5>
              Meet our experienced and dedicated instructors who guide you on
              your martial arts journey.
            </h5>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="text-center">
            <h3>How to Join</h3>
            <h5>
              Contact us today to start your martial arts journey and become a
              part of our dojo family!
            </h5>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Programs;
