import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Webcam from 'react-webcam';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import './AddStudent.css';
import logoImage from '../../assets/images/shotokan.jpeg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AddStudent = () => {
  const [student, setStudent] = useState({
    name: '',
    password: '',
    aadhaarNo: '',
    aadhaarImage: null,
    studentImage: null,
    mobileNo: '',
    email: '',
    fatherName: '',
    fatherOccupation: '',
    motherName: '',
    motherOccupation: '',
    address: '',
    qualification: '',
    dateOfBirth: '',
    sex: '',
    weight: '',
    height: '',
    dateOfJoining: '',
    contactNo: '',
    dojoName: '',
    remarks: '',
    instructorName: '',
    chiefInstructorName: '',
  });

  const [showWebcam, setShowWebcam] = useState(false);
  const [studentImageURL, setStudentImageURL] = useState(null);
  const webcamRef = useRef(null);
  const formRef = useRef();

  // Check if page should scroll to the top
  useEffect(() => {
    const shouldScrollToTop = sessionStorage.getItem('navigate');
    if (shouldScrollToTop === 'true') {
      window.scrollTo(0, 0);
      sessionStorage.removeItem('navigate');
    }
  }, []);

  // Function to resize the image to specific dimensions (e.g., 413x531)
  const resizeImage = (file, width, height) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');

          // Draw the image on the canvas with the new size
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                // Handle error
                reject(new Error('Canvas is empty'));
                return;
              }
              const resizedFile = new File([blob], file.name, { type: file.type });
              const resizedDataUrl = canvas.toDataURL(file.type);
              resolve({ file: resizedFile, dataUrl: resizedDataUrl });
            },
            file.type,
            1
          );
        };
        img.onerror = (err) => reject(err);
      };
      reader.onerror = (err) => reject(err);
    });
  };

  const handleChange = async (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      const file = files[0];
      if (file) {
        if (name === 'studentImage') {
          try {
            const resizedImage = await resizeImage(file, 300, 300);
            setStudent((prev) => ({
              ...prev,
              studentImage: resizedImage.file,
            }));
            setStudentImageURL(resizedImage.dataUrl);
          } catch (error) {
            console.error('Error resizing image:', error);
            alert('Failed to resize the image. Please try again.');
          }
        } else {
          // Handle other file inputs (e.g., aadhaarImage) without resizing
          setStudent((prev) => ({
            ...prev,
            [name]: file,
          }));
        }
      }
    } else {
      setStudent((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const captureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      try {
        const response = await fetch(imageSrc);
        const blob = await response.blob();
        const file = new File([blob], 'studentImage.jpg', { type: 'image/jpeg' });

        // Resize the captured image
        const resizedImage = await resizeImage(file, 300, 300);

        setStudent((prev) => ({
          ...prev,
          studentImage: resizedImage.file,
        }));
        setStudentImageURL(resizedImage.dataUrl);
        setShowWebcam(false);
      } catch (error) {
        console.error('Error capturing image:', error);
        alert('Failed to capture the image. Please try again.');
      }
    }
  };

  const handleRetakeImage = () => {
    setStudentImageURL(null);
    setShowWebcam(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (const key in student) {
        formData.append(key, student[key]);
      }

      const response = await axios.post('https://karatesportsclubhubli.com/add_student.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const responseText = response.data;
      const jsonString = responseText.match(/{.*}/)[0]; // Extract the JSON part
      const parsedResponse = JSON.parse(jsonString); // Parse the JSON
      const student_id = parsedResponse.student_id; // Extract the student ID
      console.log(student_id);

      console.log('Sending student name:', student.name);
      // Send student's name to ForAttendance database
      await axios.post('https://karatesportsclubhubli.com/forattendance.php', { studentName: student.name, student_id: student_id });

      alert('Student added successfully!');

      // Generate certificate and send to email
      generateAndSendCertificate({ ...student, student_id });

      // Optionally, reset the form fields after successful submission
      setStudent({
        name: '',
        password: '',
        aadhaarNo: '',
        aadhaarImage: null,
        studentImage: null,
        mobileNo: '',
        email: '',
        fatherName: '',
        fatherOccupation: '',
        motherName: '',
        motherOccupation: '',
        address: '',
        qualification: '',
        dateOfBirth: '',
        sex: '',
        weight: '',
        height: '',
        dateOfJoining: '',
        contactNo: '',
        dojoName: '',
        remarks: '',
        instructorName: '',
        chiefInstructorName: '',
      });
      setStudentImageURL(null);
    } catch (error) {
      console.error('Error adding student:', error);
      alert('Failed to add student. Please try again.');
    }
  };

  const getImageDataURL = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const generateAndSendCertificate = async (studentData) => {
    const studentImageDataURL = studentData.studentImage ? await getImageDataURL(studentData.studentImage) : null;

    const logoResponse = await fetch(logoImage);
    const logoBlob = await logoResponse.blob();
    const logoImageDataURL = await getImageDataURL(logoBlob);
    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [15, 15, 15, 15],
      content: [
        {
          image: logoImageDataURL,
          width: 180,
          alignment: 'center',
          margin: [0, -80],
        },
        {
          text: 'Reg.No.: DWR-S437-2015/16',
          style: 'left1',
          alignment: 'right',
          margin: [0, 0, 10, 2],
        },
        {
          text: 'SHOTOKAN KARATE-DO ASSOCIATION\nYOUTH SPORTS CLUB, HUBBALLI KARNATAKA',
          style: 'header',
          alignment: 'center',
          margin: [0, 3],
        },
        {
          text: 'Dojo H.Q: Shri Siddeshwara Kailasa Mantapa Hosamath P.B Road, Unkal, Hubballi-31.',
          style: 'content',
          alignment: 'center',
          margin: [0, 3],
        },
        {
          text: 'Cell: 9916836930   Email: shotokankaratehubli@gmail.com',
          style: 'content',
          alignment: 'center',
          margin: [0, 3],
        },
        {
          text: 'SENSEI: RAJESH B.YARAGATTI\nBlack Belt 3rd Dan, President S.K.A.Y.S.C',
          style: 'left1',
          alignment: 'left',
          margin: [15, 5],
        },
        {
          text: 'Certificate of Enrollment',
          style: 'title',
          alignment: 'center',
          margin: [0, 5],
        },
        {
          columns: [
            {
              width: '*',
              stack: [
                {
                  columns: [
                    {
                      width: 'auto',
                      stack: [
                        { text: 'Name', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Student ID', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Password', bold: true, margin: [15, 0, 5, 5] },
                        { text: "Father's Name", bold: true, margin: [15, 0, 5, 5] },
                        { text: "Father's Occupation", bold: true, margin: [15, 0, 5, 5] },
                        { text: "Mother's Name", bold: true, margin: [15, 0, 5, 5] },
                        { text: "Mother's Occupation", bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Mobile No', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Address', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Qualification', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Date of Birth', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Gender', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Weight', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Height', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Date of Joining', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Alt Contact No', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Instructor ', bold: true, margin: [15, 0, 5, 5] },
                        { text: 'Chief Instructor ', bold: true, margin: [15, 0, 5, 5] },
                      ],
                    },
                    {
                      width: 'auto',
                      stack: [
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                        { text: ':', margin: [0, 0, 5, 5] },
                      ],
                    },
                    
                    {
                      width: '*',
                      stack: [
                        { text: studentData.name, margin: [0, 0, 0, 5] },
                        { text: studentData.student_id, margin: [0, 0, 0, 5] },
                        { text: studentData.password, margin: [0, 0, 0, 5] },
                        { text: studentData.fatherName, margin: [0, 0, 0, 5] },
                        { text: studentData.fatherOccupation, margin: [0, 0, 0, 5] },
                        { text: studentData.motherName, margin: [0, 0, 0, 5] },
                        { text: studentData.motherOccupation, margin: [0, 0, 0, 5] },
                        { text: studentData.mobileNo, margin: [0, 0, 0, 5] },
                        { text: studentData.address, margin: [0, 0, 0, 5] },
                        { text: studentData.qualification, margin: [0, 0, 0, 5] },
                        { text: studentData.dateOfBirth, margin: [0, 0, 0, 5] },
                        { text: studentData.sex, margin: [0, 0, 0, 5] },
                        { text: studentData.weight, margin: [0, 0, 0, 5] },
                        { text: studentData.height, margin: [0, 0, 0, 5] },
                        { text: studentData.dateOfJoining, margin: [0, 0, 0, 5] },
                        { text: studentData.contactNo, margin: [0, 0, 0, 5] },
                        { text: studentData.instructorName, margin: [0, 0, 0, 5] },
                        { text: studentData.chiefInstructorName, margin: [0, 0, 0, 5] },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              image: studentImageDataURL, // assuming this is the correct image data
              width: 100,                // Width of the image in the document
              height: 120,               // Height of the image in the document
              alignment: 'right',        // Aligns the image to the right
              margin: [15, 0, 10, 0],    // Margin around the image
            }
            
            
          ],
          margin: [0, 5],
        },
        {
          text: 'DECLARATION',
          style: 'subheader',
          margin: [0, 5],
        },
        {
          ul: [
            'Organizing committee is not responsible for any injury, but first aid will be provided.',
            'Admission fee will not be refunded.',
          ],
          style: 'content',
          margin: [0, 0, 0, 5],
        },
        {
          text: 'I, Mr. / Mrs. _____________________________________________________________________declare that the above statements are true to the best of my knowledge and promise to obey the rules and regulations of the organization.',
          style: 'content',
          margin: [0, 5],
        },
        {
          width: '*',
          text: 'Signature of Candidate/Parent: _____________________',
          style: 'content',
          margin: [0, 5],
        },
        {
          text: 'FOR OFFICE USE ONLY',
          style: 'subheader',
          margin: [0, 5],
        },
        {
          text: `Name of the Dojo: ${studentData.dojoName}`,
          style: 'content',
          margin: [0, 2],
        },
        {
          text: `Remarks and Renewal Details: ${studentData.remarks}`,
          style: 'content',
          margin: [0, 2],
        },
        {
          columns: [
            {
              width: '*',
              text: 'Signature of Instructor: _____________________',
              style: 'content',
              margin: [0, 15],
            },
            {
              width: '*',
              text: 'Signature of Chief Instructor: _____________________',
              style: 'content',
              margin: [0, 15],
            },
          ],
        },
      ],
      styles: {
        header: {
          fontSize: 13,
          bold: true,
          color: '#003366',
          alignment: 'center',
        },
        title: {
          fontSize: 15,
          bold: true,
          color: '#005b96',
          alignment: 'center',
        },
        subheader: {
          fontSize: 12,
          bold: true,
          color: '#005b96',
          alignment: 'center',
        },
        left1: {
          fontSize: 12,
          bold: true,
          color: '#000000',
        },
        content: {
          fontSize: 12,
          color: '#000000',
          fontWeight: 'bold',
        },
      },
      defaultStyle: {
        font: 'Roboto',
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    // Download the PDF
    pdfDocGenerator.download(`certificate_${studentData.name}.pdf`);
    pdfDocGenerator.getBase64(async (data) => {
      try {
        const response = await axios.post('https://karatesportsclubhubli.com//certificatesmail.php', {
          email: studentData.email,
          pdfData: data,
          // Password
        });
        alert('Certificates are sent successfully to the mail');
      } catch (error) {
        console.error('Error sending certificate email:', error);
        alert('Failed to send certificate email. Please try again.');
      }
    });
  };

  return (
    <div className="add-student-container">
      <form className="student-form" onSubmit={handleSubmit} ref={formRef}>
        <div className="form-header">
          <h2>Student Registration</h2>
          {studentImageURL && <img src={studentImageURL} alt="Student" className="student-image" />}
        </div>
        <div className="form-section">
          <div className="form-column">
            <label>
              Name:
              <input type="text" name="name" value={student.name} onChange={handleChange} required />
            </label>
            <label>
              Password:
              <input type="password" name="password" value={student.password} onChange={handleChange} required />
            </label>
            <label>
              Aadhaar No:
              <input type="text" name="aadhaarNo" value={student.aadhaarNo} onChange={handleChange} required />
            </label>
            <label>
              Aadhaar Image:
              <input type="file" name="aadhaarImage" onChange={handleChange} required />
            </label>
            <label>
              Contact No:
              <input type="number" name="mobileNo" value={student.mobileNo} onChange={handleChange} required />
            </label>
            <label>
              Email:
              <input type="email" name="email" value={student.email} onChange={handleChange} required />
            </label>
          </div>
          <div className="form-column">
            <label>
              Father's Name:
              <input type="text" name="fatherName" value={student.fatherName} onChange={handleChange} required />
            </label>
            <label>
              Father's Occupation:
              <input type="text" name="fatherOccupation" value={student.fatherOccupation} onChange={handleChange} required />
            </label>
            <label>
              Mother's Name:
              <input type="text" name="motherName" value={student.motherName} onChange={handleChange} required />
            </label>
            <label>
              Mother's Occupation:
              <input type="text" name="motherOccupation" value={student.motherOccupation} onChange={handleChange} required />
            </label>
            <label>
              Address:
              <textarea name="address" value={student.address} onChange={handleChange} required />
            </label>
            <label>
              Qualification:
              <input type="text" name="qualification" value={student.qualification} onChange={handleChange} required />
            </label>
          </div>
          <div className="form-column">
            <label>
              Date of Birth:
              <input type="date" name="dateOfBirth" value={student.dateOfBirth} onChange={handleChange} required />
            </label>
            <label>
              Sex:
              <input type="text" name="sex" value={student.sex} onChange={handleChange} required />
            </label>
            <label>
              Weight:
              <input type="text" name="weight" value={student.weight} onChange={handleChange} required />
            </label>
            <label>
              Height:
              <input type="text" name="height" value={student.height} onChange={handleChange} required />
            </label>
            <label>
              Date of Joining:
              <input type="date" name="dateOfJoining" value={student.dateOfJoining} onChange={handleChange} required />
            </label>
            <label>
              Alt Contact No:
              <input type="number" name="contactNo" value={student.contactNo} onChange={handleChange} required />
            </label>
          </div>
          <div className="form-column">
            <label>
              Dojo Name:
              <input type="text" name="dojoName" value={student.dojoName} onChange={handleChange} required />
            </label>
            <label>
              Remarks:
              <input type="text" name="remarks" value={student.remarks} onChange={handleChange} />
            </label>
            <label>
              Instructor Name:
              <input type="text" name="instructorName" value={student.instructorName} onChange={handleChange} required />
            </label>
            <label>
              Chief Instructor Name:
              <input type="text" name="chiefInstructorName" value={student.chiefInstructorName} onChange={handleChange} required />
            </label>
            <div className="student-image-box">
              <label>
                Student Image:
                <input type="file" name="studentImage" onChange={handleChange} />
              </label>
              {showWebcam ? (
                <div>
                  <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
                  <button type="button" onClick={captureImage}>
                    Capture Image
                  </button>
                  <button type="button" onClick={() => setShowWebcam(false)}>
                    Cancel
                  </button>
                </div>
              ) : (
                <div>
                  <button type="button" onClick={() => setShowWebcam(true)}>
                    Take a Picture
                  </button>
                </div>
              )}
              {studentImageURL && (
                <div>
                  <button type="button" onClick={handleRetakeImage}>
                    Retake Image
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddStudent;
